import { fetchFromAPI, getRequestOptions } from '~/lib/baseApi'
import type { GetCompanyResponse } from '~/feature/companies/types'

const getCompany = async (companyCode: string): Promise<GetCompanyResponse> => {
  return await fetchFromAPI<Promise<GetCompanyResponse>>({
    endpoint: `/companies/${companyCode}`,
    options: getRequestOptions(),
  })
}

export default getCompany
