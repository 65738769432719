import { useQuery } from '@tanstack/react-query'
import {
  type ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useLocation, useParams } from 'react-router-dom'
import getCompany from '~/feature/companies/api/getCompany'
import companiesQueryKeys from '~/feature/companies/queryKeys/companiesQueryKeys'
import { get } from '../lib/fetch_api'
import type { Company } from '../models/company'

export type UserType = 'administrator' | 'person' | null

export interface User {
  id: number
  name: string
  email: string
  kana?: string
  memo?: string
}

type AuthContextType = {
  isLoading: boolean
  userType: UserType | null
  setUserType: (type: UserType | null) => void
  user: User | null
  setUser: (user: User | null) => void
  company: Company | null
  setCompany: (company: Company | null) => void
  isAdmin: boolean
  refreshAuth: () => void
}

const AuthContext = createContext<AuthContextType | undefined>(undefined)

export default AuthContext

interface AuthProviderProps {
  children: ReactNode
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [userType, setUserType] = useState<UserType>(null)
  const [user, setUser] = useState<User | null>(null)
  const [company, setCompany] = useState<Company | null>(null)
  const location = useLocation()
  const pageAnswer = location.pathname.startsWith('/q/')
  const { company: company_code } = useParams<{ company: string }>()
  const isAdmin = userType === 'administrator'
  const pageCompanyCode = location.pathname.startsWith(
    `/companies/${company_code}`,
  )

  useQuery({
    queryKey: companiesQueryKeys.detail(company_code ?? ''),
    queryFn: async () => {
      if (company_code === undefined) {
        setCompany(null)
        return null
      }
      const data = await getCompany(company_code)
      if (pageCompanyCode) {
        setCompany(data.company)
      } else {
        setCompany(null)
      }
      return data
    },
    enabled: isAdmin,
  })

  const fetchAuthData = useCallback(() => {
    setIsLoading(true)
    get<{
      user: User
      type: UserType
      company: Company
    }>('/me')
      .then(data => {
        const user = data.user
        setUser(user)
        setUserType(data.type)
        setCompany(data.company)
      })
      .catch(() => {
        setUser(null)
        setUserType(null)
        setCompany(null)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  useEffect(() => {
    if (!pageAnswer) {
      fetchAuthData()
    } else {
      setIsLoading(false)
    }
  }, [fetchAuthData, pageAnswer])

  const setUserAndSave = (user: User | null) => {
    setUser(user)
  }

  const setUserTypeAndSave = (type: UserType | null) => {
    setUserType(type)
  }

  const setCompanyAndSave = (company: Company | null) => {
    setCompany(company)
  }

  return (
    <AuthContext.Provider
      value={{
        isLoading,
        user,
        userType,
        company,
        isAdmin,
        setUser: setUserAndSave,
        setUserType: setUserTypeAndSave,
        setCompany: setCompanyAndSave,
        refreshAuth: fetchAuthData,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}
